/**
 * Hosteller
 * Hosteller Template created for hostels, students hotels, guest houses, small hotel, resort, room reservation, travel and more
 * Exclusively on https://1.envato.market/hosteller-html
 *
 * @encoding        UTF-8
 * @version         1.0.3
 * @copyright       (C) 2018 - 2022 Merkulove ( https://merkulov.design/ ). All rights reserved.
 * @license         Envato License https://1.envato.market/KYbje
 * @contributors    Lamber Lilit (winter.rituel@gmail.com)
 * @support         help@merkulov.design
 **/
 @font-face {
    font-family: 'Spy Agency';
    src: url('../fonts/SpyAgencyBold.woff2') format('woff2'),
        url('../fonts/SpyAgencyBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}



 @font-face {
    font-family: 'Eurostile';
    src: url('../fonts/Eurostile-Oblique.woff2') format('woff2'),
        url('../fonts/Eurostile-Oblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Eurostile';
    src: url('../fonts/EurostileBold.woff2') format('woff2'),
        url('../fonts/EurostileBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eurostile';
    src: url('../fonts/EurostileBold.woff2') format('woff2'),
        url('../fonts/EurostileBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eurostile';
    src: url('../fonts/Eurostile-Oblique.woff2') format('woff2'),
        url('../fonts/Eurostile-Oblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'EuroStyle';
    src: url('../fonts/EuroStyleNormal.woff2') format('woff2'),
        url('../fonts/EuroStyleNormal.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eurostile';
    src: url('../fonts/Eurostile-Oblique.woff2') format('woff2'),
        url('../fonts/Eurostile-Oblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Eurostile Extended';
    src: url('../fonts/EurostileExtended-Roman.woff2') format('woff2'),
        url('../fonts/EurostileExtended-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'EuroStyle';
    src: url('../fonts/EuroStyleNormal.woff2') format('woff2'),
        url('../fonts/EuroStyleNormal.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eurostile';
    src: url('../fonts/EurostileBold.woff2') format('woff2'),
        url('../fonts/EurostileBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'EuroStyle';
    src: url('../fonts/EuroStyleNormal.woff2') format('woff2'),
        url('../fonts/EuroStyleNormal.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eurostile Extended';
    src: url('../fonts/EurostileExtended-Roman.woff2') format('woff2'),
        url('../fonts/EurostileExtended-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Eurostile Extended';
    src: url('../fonts/EurostileExtended-Roman.woff2') format('woff2'),
        url('../fonts/EurostileExtended-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face{font-family:OpenSans;src:local("OpenSans-Regular"),url(../fonts/OpenSans-Regular.woff2) format("woff2"),url(../fonts/OpenSans-Regular.woff) format("woff");font-weight:400;font-style:normal;font-display:swap}@font-face{font-family:OpenSans;src:local("OpenSans-SemiBold"),url(../fonts/OpenSans-SemiBold.woff2) format("woff2"),url(../fonts/OpenSans-SemiBold.woff) format("woff");font-weight:600;font-style:normal;font-display:swap}@font-face{font-family:OpenSans-Bold;src:local("OpenSans-Bold"),url(../fonts/OpenSans-Bold.woff2) format("woff2"),url(../fonts/OpenSans-Bold.woff) format("woff");font-weight:700;font-style:normal;font-display:swap}@font-face{font-family:Mulish;src:local("Mulish-Medium"),url(../fonts/Mulish-Medium.woff2) format("woff2"),url(../fonts/Mulish-Medium.woff) format("woff");font-weight:500;font-style:normal;font-display:swap}@font-face{font-family:Mulish;src:local("Mulish-SemiBold"),url(../fonts/Mulish-SemiBold.woff2) format("woff2"),url(../fonts/Mulish-SemiBold.woff) format("woff");font-weight:600;font-style:normal;font-display:swap}@font-face{font-family:Mulish;src:local("Mulish-Bold"),url(../fonts/Mulish-Bold.woff2) format("woff2"),url(../fonts/Mulish-Bold.woff) format("woff");font-weight:700;font-style:normal;font-display:swap}@font-face{font-family:Mulish;src:local("Mulish-ExtraBold"),url(../fonts/Mulish-ExtraBold.woff2) format("woff2"),url(../fonts/Mulish-ExtraBold.woff) format("woff");font-weight:800;font-style:normal;font-display:swap}@font-face{font-family:Mulish;src:local("Mulish-Black"),url(../fonts/Mulish-Black.woff2) format("woff2"),url(../fonts/Mulish-Black.woff) format("woff");font-weight:900;font-style:normal;font-display:swap}
/*# sourceMappingURL=../sourcemaps/preload.css.map */

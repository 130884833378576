
$color-accent-primary: #003A98;


.theme-element--accent {
  background-color: $color-accent-primary;
}

.accent {
  background-color: $color-accent-primary;
}

.hero_main-title {
  text-transform: uppercase;
  font-size: 35px;
  color: #000;
}
.hero.section {
  padding-top: 20px;
}
.card.accent {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}
img:not(img) {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.hero-container .hero {
  background:white;
}

.theme-element {
  background-color: $color-accent-primary;
  color: white;
  border-radius: 20px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  font-family: 'Eurostile';
  text-transform: uppercase;
}
.theme-element--light {
  background-color: rgb(199, 206, 240);
  color: $color-accent-primary;
}

.icon {
  color: white;
}

@media screen and (min-width: 991.98px) {
  .section--nopb {
    padding: 50px 0 0;
  }
  .footer_copyright-text {
    text-align: left;
  }
}

@media screen and (min-width: 1199.98px) {
  .about_stages-main_header {
    max-width: 500px;
  }
}

.promo .info_list .list-item .main_text {
  max-width: 230px;
  font-size: 16px;
  line-height: 1.4;
}

.icon-stream {
  color: $color-accent-primary;
}

.reviews_slider--main {
  border-left: 3px solid rgb(214, 233, 214);
}

.about_stages-main_list .list-item .media:after {
  content: "";
  position: absolute;
  bottom: -110px;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  background: rgb(214, 233, 214);
  height: 140%;
  z-index: -1;
}

.about_benefits-list_item .number {
  margin-bottom: 10px;
  color: $color-accent-primary;
}

.reviews_slider-slide_main {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 10px 0;
}

h3 {
  font-size: 33px;
  font-family: 'Mulish' !important;
}
@media screen and (max-width: 992px) {
  .hero_main-title {
    font-size: 40px;
    font-family: "Mulish";
    letter-spacing: -2px;
  }
  .overlay {
    position: relative;
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.20;
    }
  }
  h3 {
    font-size: 30px;
    font-family: 'Mulish' !important;
  }
  .theme-element.btn {
    height: 48px;
    font-weight: 800;
    font-size: 14px;
    line-height: 1;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 0 20px;
    font-family: 'Eurostile';
    text-transform: uppercase;
  }
  .container--hero .theme-element.btn {
    font-size: 13px;
  } 
  .about_stages-media img {
    height: 300px;
  }

  .about_main {
    text-align: left;
    margin-bottom: 20px;
  }
  .reviews.section {
    padding-top: 30px;
  }
  .rooms_list.d-md-flex.flex-wrap.row.mt-4 {
    margin-top: 0px !important;
  }

}

.header.sticky {
  height: 70px;
}

.header_nav-list_item .nav-link--contacts {
  background-color: rgb(214, 233, 214);
  color: $color-accent-primary;
}

.footer_copyright {
  background-color: #333;
  color: #fff;
}
.footer_copyright p {
  color: rgb(220, 220, 220);
}
.footer.accent {
  background: #333;
}
ul {
  padding-left: 0;
}
.navbar-light .navbar-toggler {
  border: none;
}

.hero_main-content {
  margin-bottom: 20px;
}
.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
}
.bg-light {
  background: #fff !important;
}
.navbar-light .navbar-nav .nav-link {
  margin-right: 70px;
  color: #000;
  font-weight: 600;
  font-size: 15px;
  margin-top: 0px;
  font-family: 'Eurostile';
  text-transform: uppercase;
}
.nav-link {
  margin-right: 70px;
  color: #000;
  font-weight: 600;
  font-size: 15px;
  margin-top: 20px;
}
.nav-link.theme-element.theme-element--accent.btn {
  color: #fff;
  font-weight: 800;
}
.navbar-nav {
  align-items: center;
}
@media screen and (max-width: 992px) {
  .hidden-sm {
    display: none;
  }
  .offcanvas-body .navbar-nav {
    align-items: flex-start;
  }
}
ul.about_benefits-list {
  padding-left: 20px;
}
.swiper {
  height: 300px;
}
@media screen and (max-width: 992px) {
  .headroom-wrapper {
    height: 120px !important;
  }
  .swiper {
    height: 390px;
  }
  .owned {
    text-align: center;
    font-size: 20px;
    img {
      margin: auto;
    }
    padding-top: 35px;
    margin-top: 35px;
  }

  .hero.section {
    padding-bottom: 30px;
  }
}
.headroom--pinned {
  z-index: 9999 !important;
}
@media screen and (min-width: 575.98px) {
  .reviews_slider--main .swiper-slide {
    padding: 30px;
  }
  .promo {
    background-color: #fff;
  }
}
p.input-grouping {
  font-size: 15px;
  span {
    font-size: 14px;
  }
}
.form-check-input:checked {
  background-color: $color-accent-primary;
  border-color: $color-accent-primary;
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(65, 253, 13, 0.25);
}

.btn:hover {
  color: #fff;
}

.theme-element--light:hover {
  color: #333;
}
.reviews {
  .swiper-button-prev,
  .swiper-button-next {
    &:hover {
      background: rgb(214, 233, 214);
      .icon {
        color: #333;
      }
    }
  }
}
.cls-1 {
  stroke: #fff;
  stroke-width: 1.5px;
}
.navbar-toggler:focus {
  box-shadow: none;
}

.reviews_slider--main .swiper-slide {
  height: auto;
  padding: 30px;
  background: #fff;
}

p a {
  color: $color-accent-primary;;
  text-decoration: underline;
  &:hover {
    color: $color-accent-primary;;
    text-decoration: none;
  }
}
.name.h6 {
  font-weight: 400;
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding: 0 20px;
  }
  .section.location {
    margin-bottom: 40px;
  }
}

.hero-container {
  position: relative;
}

@media screen and (min-width: 767.98px) {
  .hero.section {
    position: absolute;
    display: block;
    bottom: 50%;
    right: 50%;
    padding-bottom: 0px;
    padding-top: 0px;
    /* background-color: #F7FAFD; */
    border-radius: 0 20px 0 0px;
    text-align: center;
    transform: translate(50%, 50%);
    h2 {
      color: white;
    }
    .hero-main:before {
      background: transparent;
    }
    p {
      color: white;
    }
    h5 {
      color: white;
    }
    .container {
      max-width: 1500px;
    }
    .hero_main-title {
      margin-bottom: 15px;
      font-size: 40px;
      width: 600px;
      margin: auto;
    }

  }
  .hero-container .hero {
    background:transparent;
  }
  .hero-container {
    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.70;
    }
    
  }
}

h2, h3, h4 {
  font-family: 'Eurostile';
}


.zero-nav {
  background-color: $color-accent-primary;
}

@media screen and (min-width: 767.98px) {
  .section {
      padding: 60px 0;
  }
}

@media screen and (min-width: 767.98px) {
  .hero_main-content {
      margin-bottom: 20px;
      width: 520px;
      margin: auto;
  }
}



@media screen and (min-width: 1199.98px) {

.hero .container--hero {
    max-width: unset;
    margin-right: 0;
    margin-left: 0;
    /* margin-left: calc(50vw - 570px); */
    padding: 0;
}

span.line {
  display: none;
}

}

.rooms {
  background-color: #F7FAFD;
}

.about_media {
  img {
    border-radius: 10px;
  }
}

.hero_main-content .line {
  border-left: 3px solid $color-accent-primary;
  margin-right: 17px;
}

.hero_main-content p {
  margin-bottom: 0;
}

.hero.section {
  .item-wrapper {
    margin-top: 25px !important;
  }
}

.phone {
  text-align: right;
  p {
    font-weight: bold;
  }
}

.icon-phone {
  position: relative;
  bottom: -3px;
  font-weight: bold;
  font-size: 21px;
}



.zero-nav {
  padding-left: 10px;
  .icon-phone {
    position: relative;
    bottom: -1px;
    font-size: 15px;
    color: white;
  }
  a {
    color: white;
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: inline-block;
  }
  @media screen and (min-width: 1199.98px) {
    .icon-phone, a {
      display: none;
    }
    height: 10px;
  }
}